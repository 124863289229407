import { formatNumberOrDefault } from '@Modules/common/utils/formatNumberOrDefault'
import { OfferRentabilityMetricsType } from '@Modules/offers/domain/entities/offerDetails'
import { noContentPlaceholder, tableDataFormattingSettings } from 'ufinet-web-functions'

type OfferDetailsRentabilityProps = OfferRentabilityMetricsType & {
	title: string
	translate: (key: string) => string
}

export const OfferDetailsRentability = ({
	van,
	tir,
	payBack,
	viable,
	title,
	translate,
}: OfferDetailsRentabilityProps) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{title}</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.RENTABILITY.VAN')}: </b>
					{formatNumberOrDefault(van, tableDataFormattingSettings)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.RENTABILITY.PAYBACK')}: </b>
					{formatNumberOrDefault(payBack, tableDataFormattingSettings)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.RENTABILITY.TIR')}: </b>
					{formatNumberOrDefault(tir, tableDataFormattingSettings)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.RENTABILITY.VIABLE')}: </b>
					{viable || noContentPlaceholder}
				</div>
			</div>
		</>
	)
}
