import { OfferResponse, PagedOffersResponse } from '@Modules/offers/application/dtos/PagedOffersResponse'
import { Offer, PagedOffer } from '@Modules/offers/domain/entities/offer'
import { mapFromPagedResponseToPagingData, noContentPlaceholder, TranslatorFunction } from 'ufinet-web-functions'

export const offerPagedMapper = (
	pagedOfferResponse: PagedOffersResponse,
	translate: TranslatorFunction
): PagedOffer => {
	return {
		pagingData: mapFromPagedResponseToPagingData(pagedOfferResponse),
		offers: pagedOfferResponse.data.map((offer: OfferResponse): Offer => {
			return {
				id: offer.id,
				name: offer.name || noContentPlaceholder,
				arc: offer.arc,
				clientName: offer.clientName || noContentPlaceholder,
				currencyName: offer.currencyName || noContentPlaceholder,
				kamName: offer.kamName || noContentPlaceholder,
				offerId: offer.offerId,
				isAwardInternalStatusOk: offer.isAwardInternalStatusOk,
				offerState: offer.offerState ? translate(`OFFERS.STATES.${offer.offerState}`) : noContentPlaceholder,
				offerStateId: offer.offerStateId,
			}
		}),
	}
}
