import { i18nMessages } from 'ufinet-web-components'
import enMessages from './en.json'
import esMessages from './es.json'
import ptMessages from './pt.json'

export const offersI18nMessages: i18nMessages = {
	en: {
		...enMessages,
	},
	es: {
		...esMessages,
	},
	pt: {
		...ptMessages,
	},
}
