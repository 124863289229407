import { FC } from 'react'
import { Link } from 'react-router-dom'
import { AsideMenuMain } from './AsideMenuMain'

import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons/faMapLocationDot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PATH_HOME } from '@Routing/protected/routerNameMap'

// The KT aside toggle is being troublesome, for now,
// we'll check and toggle it manually manipulating body attributes.
const bodyAsideToggleAttribute = 'data-kt-aside-minimize'
export const toggleSidebar = () => {
	const minimized = document.body.getAttribute(bodyAsideToggleAttribute)
	const newMinimizedValue = minimized === 'on' ? 'off' : 'on'
	document.body.setAttribute(bodyAsideToggleAttribute, newMinimizedValue)
}

const AsideMobile: FC = () => {
	return (
		<div
			className="menu menu-sub menu-sub-slide menu-column w-250px w-lg-325px"
			data-kt-menu="true"
			id="kt_aside_mobile"
		>
			<div className="aside-logo flex-column-auto justify-content-center" id="kt_aside_logo">
				<Link to={PATH_HOME}>
					<div className="d-flex align-items-center justify-content-center">
						<FontAwesomeIcon className="logo-minimize color-primary" size="3x" icon={faMapLocationDot} />
						<h1 className="logo fw-boldest my-auto ms-4 text-primary">TEMPLATE</h1>
					</div>
				</Link>
			</div>

			<div className="aside-menu flex-column-fluid">
				<div className="menu menu-column">
					<AsideMenuMain />
				</div>
			</div>
		</div>
	)
}

export { AsideMobile }
