import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'
import { offersI18nMessages } from '../../../modules/i18n/domain/messages'

const OffersI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={offersI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { OffersI18nProvider }
