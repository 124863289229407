import { PagedOfferRequest } from '@Modules/offers/application/dtos/PagedOfferRequest'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'

import { defaultOptionsFetch, FetchAPI, IAuthData, IPagingRequestParameters } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_OFE}api/offers`
export const HttpOfferRepository: (authData: IAuthData) => OfferRepository = (authData: IAuthData) => {
	const fetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const getFilters = async () => await FetchAPI.get(`${url}/filters`, fetchOptions)

	const getOffers = async (pagedOfferRequest: PagedOfferRequest) =>
		await FetchAPI.get_params(url, pagedOfferRequest, fetchOptions)

	const getOfferDetails = async (offerId: string) => await FetchAPI.get(`${url}/${offerId}`, fetchOptions)

	const getOfferProducts = async (offerId: string, pagingRequestParameters: IPagingRequestParameters) =>
		await FetchAPI.get_params(`${url}/${offerId}/products`, pagingRequestParameters, fetchOptions)

	const postAwardOffer = async (offerId: string) => FetchAPI.post(`${url}/${offerId}/award`, {}, fetchOptions)

	return {
		getOffers,
		getFilters,
		getOfferDetails,
		getOfferProducts,
		postAwardOffer,
	}
}
