import { SortingDirection } from 'ufinet-web-functions'

export function getSortingDirectionKey(value: number): SortingDirection {
	switch (value) {
		case 1:
			return SortingDirection.ASCENDING
		default:
			return SortingDirection.DESCENDING
	}
}
