import { getOfferProductCols } from '@Components/OfferProduct/Table/Columns/OfferProductColumns'
import { emptyFunction } from '@Modules/common/utils/emptyFunction'
import { useOfferProducts } from '@Modules/offers/application/queries'
import { PagedOfferProduct } from '@Modules/offers/domain/entities/offerProduct'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { DataTablePFSEvent } from 'primereact/datatable'
import { FC, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Table } from 'ufinet-web-components'
import { PagingData, TranslatorFunction, useInternalUser, useTranslator } from 'ufinet-web-functions'

interface OfferProductTableProps {
	pagingEvents: { paging: PagingData; setPagination: (event: DataTablePFSEvent) => void }
	translate: TranslatorFunction
	isProductOfferLoading: boolean
	offerProductData?: PagedOfferProduct
	isInternalUser: boolean
}

export const OfferProductTable: FC<OfferProductTableProps> = ({
	translate,
	isProductOfferLoading,
	offerProductData,
	pagingEvents: { paging, setPagination },
	isInternalUser,
}) => {
	const productOffersCols = useMemo(() => getOfferProductCols(isInternalUser, translate), [isInternalUser, translate])

	return (
		<Table
			dataKey="offersTable"
			className="fs-4 service-table"
			cols={productOffersCols}
			content={offerProductData?.offerProducts}
			afterTablePFSEvent={setPagination}
			lazySettings={
				paging && {
					...paging,
					loading: isProductOfferLoading,
					onPage: emptyFunction,
					onFilter: emptyFunction,
					onSort: emptyFunction,
				}
			}
			emptyMessage={isProductOfferLoading ? translate('LOADING_DOTS') : undefined}
		/>
	)
}

interface OfferProductTableHOCProps {
	offerRepository: OfferRepository
	offerId: string | undefined
}

export const OfferProductTableHOC = ({ offerRepository, offerId }: OfferProductTableHOCProps) => {
	const defaultPaging: PagingData = useMemo(
		() => ({
			pageNumber: 0,
			pageSize: 10,
			totalElements: 0,
			totalPages: 0,
		}),
		[]
	)

	const [productPaging, setProductPaging] = useState<PagingData>(defaultPaging)
	const [productTablePFSEvent, setProductTablePFSEvent] = useState<DataTablePFSEvent>()

	const translate = useTranslator()
	const isInternalUser = useInternalUser()

	const onErrorRetrievingData = (errorId: string) => toast.error(translate(`ERROR_${errorId}`))

	const { data: offerProductsData, isLoading: isProductLoading } = useOfferProducts(
		offerRepository,
		offerId ?? '',
		productTablePFSEvent,
		translate,
		onErrorRetrievingData,
		Boolean(offerId)
	)

	useEffect(() => {
		setProductPaging(offerProductsData?.pagingData ?? defaultPaging)
	}, [offerProductsData, defaultPaging])

	return (
		<OfferProductTable
			translate={translate}
			isProductOfferLoading={isProductLoading}
			offerProductData={offerProductsData}
			pagingEvents={{ paging: productPaging, setPagination: setProductTablePFSEvent }}
			isInternalUser={isInternalUser}
		/>
	)
}
