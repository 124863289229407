import { PagingData } from 'ufinet-web-functions'

export type PagedOffer = {
	pagingData?: PagingData
	offers: Offer[]
}

export type Offer = {
	id: string
	offerId: string
	name: string
	clientName: string
	arc: number
	currencyName: string
	kamName: string
	offerState: string
	offerStateId: number
	isAwardInternalStatusOk?: boolean
}

export enum OfferState {
	IN_ELABORATION = 1,
	IN_PROGRESS = 2,
	SUBMITTED = 3,
	REJECTED_BY_UFINET = 22653002,
	PENDING_AUTHORIZATION = 226540003,
	AWARDED = 4,
	REJECTED = 5,
	CANCELED = 6,
	REVIEWED = 7,
	EXPIRED = 100000000,
	NOT_VIABLE = 100000001,
	INVALIDATED = 100000002,
	LOST = 226530000,
	INVALIDATED_DUE_TO_EXPIRATION = 226530001,
}
