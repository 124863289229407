import { formatNumberOrDefault } from '@Modules/common/utils/formatNumberOrDefault'
import { OfferCostsMetricsType } from '@Modules/offers/domain/entities/offerDetails'

type OfferDetailsCostsProps = OfferCostsMetricsType & {
	title: string
	translate: (key: string) => string
}

export const OfferDetailsCosts = ({
	puntualCostFo,
	puntualCostTeams,
	puntualCostCivilWork,
	puntualCostInfrastructure,
	totalCostMO,
	title,
	translate,
}: OfferDetailsCostsProps) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{title}</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.COSTS.PUNCTUAL_COST_FO')}: </b>
					{formatNumberOrDefault(puntualCostFo)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.COSTS.PUNCTUAL_COST_TEAMS')}: </b>
					{formatNumberOrDefault(puntualCostTeams)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.COSTS.PUNCTUAL_COST_CIVIL_WORK')}: </b>
					{formatNumberOrDefault(puntualCostCivilWork)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.COSTS.PUNCTUAL_COST_INFRASTRUCTURE')}: </b>
					{formatNumberOrDefault(puntualCostInfrastructure)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.COSTS.TOTAL_COST_MO')}: </b>
					{formatNumberOrDefault(totalCostMO)}
				</div>
			</div>
		</>
	)
}
