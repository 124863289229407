import { VITE_ENV } from '@Env/index'
import { Offer } from '@Modules/offers/domain/entities/offer'
import { OfferFilters } from '@Modules/offers/domain/entities/offerFilters'
import { Link } from 'react-router-dom'
import { ColData } from 'ufinet-web-components'
import { formatCellNumber, noContentPlaceholder, tableDataFormattingSettings } from 'ufinet-web-functions'

export const getOffersCols = (filtersData: OfferFilters): ColData<Offer>[] => [
	{
		field: 'offerId',
		header: 'OFFERS.CONSULT.HEADER.OFFERID',
		nonSortable: true,
		nonFilterable: true,
		body(offer: Offer) {
			const link = VITE_ENV === 'prod' ? '/offers' : `/offers/${offer.id}`
			return <Link to={link}>{offer.offerId}</Link>
		},
	},
	{
		field: 'name',
		header: 'OFFERS.CONSULT.HEADER.NAME',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'clientName',
		header: 'OFFERS.CONSULT.HEADER.CLIENT',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'arc',
		header: 'OFFERS.CONSULT.HEADER.DIFERENCIAL.ARC',
		type: 'number',
		body: (rowData: Offer) =>
			rowData.arc ? formatCellNumber(rowData.arc, tableDataFormattingSettings) : noContentPlaceholder,
		nonFilterable: true,
	},
	{
		field: 'currencyName',
		header: 'OFFERS.CONSULT.HEADER.CURRENCY',
		nonSortable: true,
		filterOptions: filtersData.currencies,
	},
	{
		field: 'kamName',
		header: 'OFFERS.CONSULT.HEADER.KAM',
		nonSortable: true,
		filterOptions: filtersData.kams,
	},
	{
		field: 'offerState',
		header: 'OFFERS.CONSULT.HEADER.STATUS',
		nonSortable: true,
		filterOptions: filtersData.states,
	},
]
