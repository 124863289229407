import { AsideDefault } from '@Components/layout/aside/AsideDefault'
import { AsideMobile } from '@Components/layout/aside/AsideMobile'
import { OfferDetailsPageHOC } from '@Pages/offers/OfferDetailsPage'
import { QuotationPage } from '@Pages/QuotationPage'
import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { OffersPage } from 'src/pages/offers/OffersPage'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, useTranslator } from 'ufinet-web-functions'
import { PATH_CONSULT_OFFERS, PATH_HOME, PATH_QUOTATION, routerNameMap } from './routerNameMap'

export const routePermissionMap = new Map<string, Authority[]>([
	[PATH_CONSULT_OFFERS, [Authority.offersModuleRead]],
	[PATH_QUOTATION, [Authority.offersQuotationModuleRead]],
])

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route path={PATH_HOME} element={<WelcomePage appName={translate('APP.WELCOME.TITLE')} />} />
				<Route
					path={PATH_CONSULT_OFFERS}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_CONSULT_OFFERS) ?? []}>
							<OffersPage />
						</AuthRoute>
					}
				/>

				<Route
					path={`${PATH_CONSULT_OFFERS}/:offerId`}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_CONSULT_OFFERS) ?? []}>
							<OfferDetailsPageHOC />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_QUOTATION}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_QUOTATION) ?? []}>
							<QuotationPage />
						</AuthRoute>
					}
				/>

				<Route path="*" element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
