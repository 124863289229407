import { OfferTableConsult } from '@Components/Offer/Table/OfferTableConsult'
import { HttpOfferRepository } from '@Modules/offers/infrastructure/repository/HttpOfferRepository'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

const OffersPage: FC = () => {
	const authData = useContext(AuthContext)
	const offersRepository = useMemo(() => HttpOfferRepository(authData), [authData])

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<OfferTableConsult offerRepository={offersRepository} />
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { OffersPage }
