import { useOfferTableConsult } from '@Components/Offer/Table/hooks/useOfferTableConsult'
import { emptyFunction } from '@Modules/common/utils/emptyFunction'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { Table } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

interface OfferTableConsultProps {
	offerRepository: OfferRepository
}

export const OfferTableConsult = ({ offerRepository }: OfferTableConsultProps) => {
	const translate = useTranslator()

	const {
		isOfferLoading,
		offersSelected,
		onSelectionOfferChange,
		offersCols,
		offersData,
		setTablePFSEvent,
		paging,
		header,
	} = useOfferTableConsult({ offerRepository })

	return (
		<>
			<Table
				headerButtons={header.buttons}
				selectedValues={offersSelected}
				onSelectionChange={onSelectionOfferChange}
				dataKey="offerId"
				className="fs-4 service-table"
				cols={offersCols}
				content={offersData?.offers}
				afterTablePFSEvent={setTablePFSEvent}
				lazySettings={
					paging && {
						...paging,
						loading: isOfferLoading,
						onPage: emptyFunction,
						onFilter: emptyFunction,
						onSort: emptyFunction,
					}
				}
				emptyMessage={isOfferLoading ? translate('LOADING_DOTS') : undefined}
				onFilterClear={() => setTablePFSEvent(undefined)}
			/>
		</>
	)
}
