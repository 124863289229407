import { OfferDetailsGeneralInfoType } from '@Modules/offers/domain/entities/offerDetails'
import { noContentPlaceholder } from 'ufinet-web-functions'

type OfferDetailsGeneralInformationProps = OfferDetailsGeneralInfoType & {
	translate: (key: string) => string
}

export const OfferDetailsGeneralInformation = ({
	name,
	countryName,
	clientName,
	companyName,
	finalClientName,
	currencyName,
	contactName,
	funnelState,
	economicModelName,
	period,
	kamName,
	translate,
}: OfferDetailsGeneralInformationProps) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{translate('OFFER.DETAILS.GENERAL_INFORMATION.TITLE')}</h4>
			</div>
			<div className="row">
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.NAME')}: </b>
					{name || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.SALE_COUNTRY')}: </b>
					{countryName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.CLIENT')}: </b>
					{clientName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.SALE_SOCIETY')}: </b>
					{companyName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.FINAL_CLIENT')}: </b>
					{finalClientName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.CURRENCY')}: </b>
					{currencyName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.PRINCIPAL_CONTACT')}: </b>
					{contactName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.FUNNEL_STATUS')}: </b>
					{funnelState || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.ECONOMIC_MODEL')}: </b>
					{economicModelName || noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.DEADLINE')}: </b>
					{period ?? noContentPlaceholder}
				</div>
				<div className="col-md-6 mb-3">
					<b>{translate('OFFER.DETAILS.GENERAL_INFORMATION.KAM')}: </b>
					{kamName || noContentPlaceholder}
				</div>
			</div>
		</>
	)
}
