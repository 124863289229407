import { formatNumberOrDefault } from '@Modules/common/utils/formatNumberOrDefault'
import { OfferDetailsAmountsType } from '@Modules/offers/domain/entities/offerDetails'

type OfferDetailsAmountsProps = OfferDetailsAmountsType & {
	translate: (key: string) => string
}

export const OfferDetailsAmounts = ({
	nrc,
	mrc,
	mrcReal,
	nrcDiscount,
	mrcDiscount,
	mrcDiscountUpdated,
	totalAmount,
	arcReal,
	totalAmountReal,
	offerGeneratedBackLog,
	translate,
}: OfferDetailsAmountsProps) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{translate('OFFER.DETAILS.AMOUNTS.TITLE')}</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.NRC')}: </b>
					{formatNumberOrDefault(nrc)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.MRC')}: </b>
					{formatNumberOrDefault(mrc)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.TOTAL_AMOUNT')}: </b>
					{formatNumberOrDefault(totalAmount)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.ARC_DIFERENCIAL')}: </b>
					{formatNumberOrDefault(arcReal)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.MRC_DIFERENCIAL')}: </b>
					{formatNumberOrDefault(mrcReal)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.TOTAL_AMOUNT_DIFERENCIAL')}: </b>
					{formatNumberOrDefault(totalAmountReal)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.NRC_DISCOUNT')}: </b>
					{formatNumberOrDefault(nrcDiscount)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.MRC_DISCOUNT')}: </b>
					{formatNumberOrDefault(mrcDiscount)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.MRC_UPDATED_DISCOUNT')}: </b>
					{formatNumberOrDefault(mrcDiscountUpdated)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.AMOUNTS.BACKLOG')}: </b>
					{formatNumberOrDefault(offerGeneratedBackLog)}
				</div>
			</div>
		</>
	)
}
