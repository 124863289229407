import { getSortingDirectionKey } from '@Modules/common/utils/sortingUtils'
import { PagedOfferRequest } from '@Modules/offers/application/dtos/PagedOfferRequest'
import { offerDetailsMapper } from '@Modules/offers/application/mapper/OfferDetailsMapper'
import { offerPagedMapper } from '@Modules/offers/application/mapper/OfferPagedMapper'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { DataTableFilterMetaData, DataTablePFSEvent } from 'primereact/datatable'
import { useMutation, useQuery } from 'react-query'
import { SortingDirection, TranslatorFunction } from 'ufinet-web-functions'

export const useOffers = (
	repository: OfferRepository,
	tablePFSEvent: DataTablePFSEvent | undefined,
	translate: TranslatorFunction,
	onError: (errorId: string) => void,
	isEnabled = true
) => {
	const pageNumber = tablePFSEvent?.page ?? 0
	const pageSize = tablePFSEvent?.rows ?? 10
	const sortField = tablePFSEvent?.sortField
	const sortOrder = tablePFSEvent?.sortOrder

	const pagedOfferRequest: PagedOfferRequest = {
		clients: (tablePFSEvent?.filters?.clientName as DataTableFilterMetaData)?.value,
		currencies: (tablePFSEvent?.filters?.currencyName as DataTableFilterMetaData)?.value,
		kams: (tablePFSEvent?.filters?.kamName as DataTableFilterMetaData)?.value,
		states: (tablePFSEvent?.filters?.offerState as DataTableFilterMetaData)?.value,
		offerIdSearchTerm: (tablePFSEvent?.filters.global as DataTableFilterMetaData)?.value?.trim()
			? [(tablePFSEvent?.filters.global as DataTableFilterMetaData).value]
			: undefined,
		pageNumber,
		pageSize,
		sortFields: sortField ? ['arcReal', 'createdAt'] : ['createdAt'],
		sortOrders: sortOrder
			? [getSortingDirectionKey(sortOrder), SortingDirection.DESCENDING]
			: [SortingDirection.DESCENDING],
	}

	const { getOffers } = repository

	return useQuery(
		`offers-${pageNumber}-${pageSize}-${pagedOfferRequest?.clients}
		-${pagedOfferRequest?.currencies}-${pagedOfferRequest?.kams}
		-${pagedOfferRequest?.states}-${sortField}-${sortOrder}-${pagedOfferRequest?.offerIdSearchTerm}`,
		() => getOffers(pagedOfferRequest),
		{
			enabled: isEnabled,
			select: (offers) => offerPagedMapper(offers, translate),
			onError: () => onError('RETRIEVING_OFFERS'),
		}
	)
}

export const useOfferDetails = (
	repository: OfferRepository,
	offerId: string,
	translate: TranslatorFunction,
	onErrorRetrievingOfferDetails: () => void,
	isEnabled = true
) => {
	const { getOfferDetails } = repository

	return useQuery([`offerDetails-${offerId}`, offerId], () => getOfferDetails(offerId), {
		enabled: isEnabled,
		select: (offerDetails) => offerDetailsMapper(offerDetails, translate),
		onError: () => onErrorRetrievingOfferDetails(),
	})
}

export const usePostAwardOffer = (
	repository: OfferRepository,
	offerId: string,
	onSuccess: () => void,
	onError: () => void
) => {
	return useMutation({
		mutationKey: `postAwardOffer-${offerId}`,
		mutationFn: () => repository.postAwardOffer(offerId),
		onSuccess,
		onError,
	})
}
