import { offerFilterMapper } from '@Modules/offers/application/mapper/OfferFilterMapper'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'
import { useQuery } from 'react-query'
import { TranslatorFunction } from 'ufinet-web-functions'

export const useOffersFilter = (repository: OfferRepository, translate: TranslatorFunction, isEnabled = true) => {
	const { getFilters } = repository

	return useQuery(`offerFilters`, () => getFilters(), {
		enabled: isEnabled,
		select: (data) => offerFilterMapper(data, translate),
	})
}
