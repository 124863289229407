import * as ReactDOM from 'react-dom'

import 'ufinet-web-components/dist/index.css'

import './styles/style.scss'

import { QueryClient, QueryClientProvider } from 'react-query'

import { AppRoutes } from '@Routing/AppRoutes'
import { OffersI18nProvider } from 'src/components/common/i18n/OffersI18nProvider'
import { AuthProvider } from 'ufinet-web-components'
import { FrontEndApplication } from 'ufinet-web-functions'
import packageJson from '../package.json'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 300000, // 5 minute
			refetchOnWindowFocus: false,
		},
	},
})

console.info(packageJson.version)

ReactDOM.render(
	<>
		<AuthProvider application={FrontEndApplication.OFE}>
			<OffersI18nProvider>
				<QueryClientProvider client={queryClient}>
					<AppRoutes />
				</QueryClientProvider>
			</OffersI18nProvider>
		</AuthProvider>
	</>,
	document.getElementById('root')
)
