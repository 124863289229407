import { FC } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

const QuotationPage: FC = () => {
	const translate = useTranslator()
	return (
		<UfinetBox>
			<UfinetSectionBox>{translate('MENU.OFFERS.QUOTATION')}</UfinetSectionBox>
		</UfinetBox>
	)
}

export { QuotationPage }
