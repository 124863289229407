import { formatDateOrDefualt } from '@Modules/common/utils/formatDateOrDefualt'
import { OfferDetailsDatesType } from '@Modules/offers/domain/entities/offerDetails'

type OfferDetailsDatesProps = OfferDetailsDatesType & {
	translate: (key: string) => string
}

export const OfferDetailsDates = ({
	createdAt,
	presentationDate,
	awardedDate,
	acceptanceDate,
	prefeasiblityRequestDate,
	prefeasiblityReceptionDate,
	rejectionDate,
	notViableDate,
	effectiveDateFrom,
	effectiveDateTo,
	updatedAt,
	closureEstimatedDate,
	translate,
}: OfferDetailsDatesProps) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{translate('OFFER.DETAILS.DATES.TITLE')}</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.CREATED_AT')}: </b>
					{formatDateOrDefualt(createdAt)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.PRESENTATION_DATE')}: </b>
					{formatDateOrDefualt(presentationDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.AWARDED_DATE')}: </b>
					{formatDateOrDefualt(awardedDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.ACCEPTANCE_DATE')}: </b>
					{formatDateOrDefualt(acceptanceDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.PRE_FEASIBILITY_REQUEST_DATE')}: </b>
					{formatDateOrDefualt(prefeasiblityRequestDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.PRE_FEASIBILITY_RECEPTION_DATE')}: </b>
					{formatDateOrDefualt(prefeasiblityReceptionDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.REJECTION_DATE')}: </b>
					{formatDateOrDefualt(rejectionDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.NOT_VIABLE_DATE')}: </b>
					{formatDateOrDefualt(notViableDate)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.EFFECTIVE_DATE_FROM')}: </b>
					{formatDateOrDefualt(effectiveDateFrom)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.EFFECTIVE_DATE_TO')}: </b>
					{formatDateOrDefualt(effectiveDateTo)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.UPDATED_AT')}: </b>
					{formatDateOrDefualt(updatedAt)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.DATES.CLOSURE_ESTIMATED_DATE')}: </b>
					{formatDateOrDefualt(closureEstimatedDate)}
				</div>
			</div>
		</>
	)
}
