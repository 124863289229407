/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext, useState } from 'react'

import { faCircle, faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PATH_CONSULT_OFFERS, PATH_QUOTATION, routerNameMap } from '@Routing/protected/routerNameMap'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useTranslator } from 'ufinet-web-functions'

enum AsideMenu {
	NONE,
	DEFAULT,
}

const AsideMenuMain: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isDefaultActive = () => active === AsideMenu.DEFAULT

	const roles = userData?.authorities || []
	const offerPermissions = Authority.getOffersPermissions(roles)
	const offersQuotationPermissions = Authority.getOffersQuotationPermissions(roles)

	const menuMyItems: IMenuItem[] = [
		{
			to: PATH_QUOTATION,
			title: routerNameMap.get(PATH_QUOTATION),
			icon: faCircle,
			hidden: !offersQuotationPermissions.canRead,
		},
		{ to: PATH_CONSULT_OFFERS, title: routerNameMap.get(PATH_CONSULT_OFFERS), icon: faCircle },
	].map((it) => ({ ...it }))

	return (
		<>
			{offerPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faList} className="fs-2x" />}
					title={translate('MENU.OFFERS')}
					active={isDefaultActive()}
				>
					{menuMyItems
						.filter((item) => !item.hidden)
						.map((item) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.DEFAULT)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
