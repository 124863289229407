interface OfferDetailsProductsProps {
	productsTableComponent: JSX.Element
	translate: (key: string) => string
}

export const OfferDetailsProducts = ({ productsTableComponent, translate }: OfferDetailsProductsProps) => {
	return (
		<>
			<div className="row mb-3">
				<h2>{translate('OFFER.DETAILS.PRODUCTS.TITLE')}</h2>
			</div>
			<div className="row">{productsTableComponent}</div>
		</>
	)
}
