import { formatNumberOrDefault } from '@Modules/common/utils/formatNumberOrDefault'
import { OfferProduct } from '@Modules/offers/domain/entities/offerProduct'
import { ColData } from 'ufinet-web-components'
import { noContentPlaceholder, tableDataFormattingSettings } from 'ufinet-web-functions'

export const getOfferProductCols = (
	isInternalUser: boolean,
	translate: (key: string) => string
): ColData<OfferProduct>[] => [
	{
		field: 'name',
		header: 'OFFERS.CONSULT.HEADER.DESCRIPTION',
		width: '20%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'location',
		header: 'OFFERS.CONSULT.HEADER.LOCATION',
		width: '25%',
		nonSortable: true,
		nonFilterable: true,
		body: (rowData: OfferProduct) => (
			<div className="col">
				<div>
					<b>{translate('OFFERS.CONSULT.HEADER.ORIGIN')}: </b>
					{rowData.origin ?? noContentPlaceholder}
				</div>
				<div>
					<b>{translate('OFFERS.CONSULT.HEADER.DESTINATION')}: </b>
					{rowData.destination ?? noContentPlaceholder}
				</div>
			</div>
		),
	},
	{
		field: 'nrc',
		header: isInternalUser ? 'OFFER.NRC' : 'OFFER.NRC.EXTERNAL_USER',
		body: (rowData: OfferProduct) => formatNumberOrDefault(rowData.nrc),
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'mrc',
		header: isInternalUser ? 'OFFER.MRC' : 'OFFER.MRC.EXTERNAL_USER',
		width: '10%',
		body: (rowData: OfferProduct) => formatNumberOrDefault(rowData.mrc),
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'period',
		header: 'OFFERS.CONSULT.HEADER.PERIOD',
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
	},
	{
		field: 'van',
		header: 'OFFERS.CONSULT.HEADER.VAN',
		body: (rowData: OfferProduct) => (
			<div className="col">
				{rowData.ingVan
					? formatNumberOrDefault(rowData.ingVan, tableDataFormattingSettings)
					: formatNumberOrDefault(rowData.aqsVan, tableDataFormattingSettings)}
			</div>
		),
		width: '10%',
		nonSortable: true,
		nonFilterable: true,
		hidden: !isInternalUser,
	},
	{
		field: 'calculationStatus',
		header: 'OFFERS.CONSULT.HEADER.CALCULATION_STATUS',
		body: (rowData: OfferProduct) => (
			<div className="col">{rowData.aqsCalculationStatusName || noContentPlaceholder}</div>
		),
		width: '15%',
		nonSortable: true,
		nonFilterable: true,
		hidden: !isInternalUser,
	},
]
