import { formatNumberOrDefault } from '@Modules/common/utils/formatNumberOrDefault'
import { OfferDetailsPrefeasibilityType } from '@Modules/offers/domain/entities/offerDetails'
import { FC } from 'react'
import { IFormatCellNumberOptions, noContentPlaceholder } from 'ufinet-web-functions'

type Props = OfferDetailsPrefeasibilityType & {
	translate: (key: string) => string
}

// TODO: Remember to create formatNumberOrDefault in web-functions
const formatCellOptions = { figures: 0 } as IFormatCellNumberOptions

export const OfferDetailsPrefeasibility: FC<Props> = ({
	prefeasibilityTicket,
	calculationStateIng,
	calculationStateAqs,
	translate,
}) => {
	return (
		<>
			<div className="row mb-3">
				<h4>{translate('OFFER.DETAILS.PREFEASIBILITY.TITLE')}</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.PREFEASIBILITY.TICKET')}: </b>
					{formatNumberOrDefault(prefeasibilityTicket, formatCellOptions)}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.PREFEASIBILITY.CALCULATION_STATE_ING')}: </b>
					{calculationStateIng || noContentPlaceholder}
				</div>
				<div className="col-12 mb-3">
					<b>{translate('OFFER.DETAILS.PREFEASIBILITY.CALCULATION_STATE_AQS')}: </b>
					{calculationStateAqs || noContentPlaceholder}
				</div>
			</div>
		</>
	)
}
