import { offerProductsPagedMapper } from '@Modules/offers/application/mapper/OfferProductsPagedMapper'
import { OfferRepository } from '@Modules/offers/domain/repositories/OfferRepository'

import { DataTablePFSEvent } from 'primereact/datatable'
import { useQuery } from 'react-query'
import { TranslatorFunction } from 'ufinet-web-functions'

export const useOfferProducts = (
	repository: OfferRepository,
	offerId: string,
	tablePFSEvent: DataTablePFSEvent | undefined,
	translate: TranslatorFunction,
	onErrorRetrievingProducts: (errorId: string) => void,
	isEnabled = true
) => {
	const { getOfferProducts } = repository

	const pageNumber = tablePFSEvent?.page ?? 0
	const pageSize = tablePFSEvent?.rows ?? 10

	return useQuery(
		[`offerProducts-${offerId}-${pageNumber}-${pageSize}`, offerId],
		() => getOfferProducts(offerId, { pageSize, pageNumber }),
		{
			enabled: isEnabled,
			onError: () => onErrorRetrievingProducts('RETRIEVING_OFFER_PRODUCTS'),
			select: (data) => offerProductsPagedMapper(data, translate),
		}
	)
}
