export const PATH_HOME = ''

export const PATH_CONSULT_OFFERS = 'offers'
export const PATH_QUOTATION = 'quotation'

export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_CONSULT_OFFERS, 'MENU.OFFERS.CONSULT'],
	[PATH_QUOTATION, 'MENU.OFFERS.QUOTATION'],
])
