import { OfferFiltersResponse } from '@Modules/offers/application/dtos/OfferFiltersResponse'
import { OfferFilters } from '@Modules/offers/domain/entities/offerFilters'
import { SelectOptionMapper, SelectOptionTranslateMapper, TranslatorFunction } from 'ufinet-web-functions'

export const offerFilterMapper = (
	offerFilterData: OfferFiltersResponse,
	translate: TranslatorFunction
): OfferFilters => {
	return {
		clients: SelectOptionMapper(offerFilterData.clients),
		currencies: SelectOptionMapper(offerFilterData.currencies),
		kams: SelectOptionMapper(offerFilterData.kams),
		states: SelectOptionTranslateMapper(offerFilterData.states, translate, 'OFFERS.STATES'),
	}
}
